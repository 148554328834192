import * as HttpClient from './http.service';
import { GET_ALL_DOCTORS, GET_DOCTORS_BLOGS, GET_DOCTORS_FAQS, GET_DOCTORS_VIDEOS, GET_DOCTOR_CLINICS_TIMINGS, GET_DOCTOR_DETAILS_BY_CITY_SLUG, GET_TRUSTED_DOCTORS_LIST } from './url.service';

export const getAllDoctors = async ({ page, limit, city = '', search = '', like = '', type = '', pincode = null, isCurated='', gender='', experience='', fee='', specialities= '', sortBy='', languages=''}) => {
    return HttpClient.getWithOutAuth(GET_ALL_DOCTORS({ page, limit, city, search, like, type, pincode, isCurated, gender, experience, fee, specialities, sortBy, languages }));
}

export const getTrustedDoctors = async ({ page, limit, city = '', search = '', like = '', type = '' }) => {
    return HttpClient.getWithOutAuth(GET_TRUSTED_DOCTORS_LIST({ page, limit, city, search, like, type }));
}

export const getDoctorDetailsByCitySlug = async ({ city, slug }) => {
    return HttpClient.getWithOutAuth(GET_DOCTOR_DETAILS_BY_CITY_SLUG({ city, slug }));
};

export const getDoctorClinicTimings = async ({ doctorItortorsd }) => {
    return HttpClient.getWithOutAuth(GET_DOCTOR_CLINICS_TIMINGS({ doctorId }));
};

export const getDoctorBlogsListing = async ({ doctorId, limit = 6, page = 1 }) => {
    return HttpClient.getWithOutAuth(GET_DOCTORS_BLOGS({ doctorId, limit, page }));
};

export const getDoctorVideosListing = async ({ doctorId, limit = 6, page = 1 }) => {
    return HttpClient.getWithOutAuth(GET_DOCTORS_VIDEOS({ doctorId, limit, page }));
};

export const getDoctorFaqsListing = async ({ doctorId, limit = 10, page = 1, search = '' }) => {
    return HttpClient.getWithOutAuth(GET_DOCTORS_FAQS({ doctorId, limit, page, search }));
};